import { useEffect, createRef } from 'react';
import * as echarts from 'echarts/core';
import {
  TooltipComponent,
  GridComponent,
  VisualMapComponent,
  LegendComponent
} from 'echarts/components';
import { ScatterChart } from 'echarts/charts';
import { UniversalTransition } from 'echarts/features';
import { CanvasRenderer } from 'echarts/renderers';
import { useTranslation } from 'react-i18next'

echarts.use([
  TooltipComponent,
  GridComponent,
  VisualMapComponent,
  LegendComponent,
  ScatterChart,
  CanvasRenderer,
  UniversalTransition
]);

const vehicleType = ({ chartList = [], type, unit }) => {
  const { t } = useTranslation();
  const dom = createRef()
  function initChartRender(list=[]) {
    const { count, Value } = list.reduce((p, v) => {
      p.count.push(v.Count)
      p.Value.push(v.Value)
      return p
    }, { count: [], Value: [] })
    const chartDom1 = dom.current
    const myChart1 = echarts.init(chartDom1);
    const option1 = {
      title: {
        text: t(`vehicleRisk.${type}`) +' '+`${unit ? `(${t(`units.${unit}`)})` :''}`,
        textStyle: {
          fontWeight: 'normal',
          fontSize: '16px',
          color: '#627d77',
          // fontFamily: sans-serif
        },
        left: '3%',
        top: '1%'
      },
      xAxis: {
        type: 'category',
        data: count,
        axisLabel:{
          interval:0,
          rotate:30,//倾斜度 -90 至 90 默认为0
          textStyle:{
            fontWeight:"normal",
            fontSize:10
          }
        },
        splitLine: {
          show: true,
          lineStyle: {
            type: 'dashed'
          }
        },
      },
      yAxis: {
        type: 'value',
        splitLine: {
          show: true,
          lineStyle: {
            type: 'dashed'
          }
        },
      },
      series: [
        {
          data: Value,
          type: 'bar',
          barWidth: '30%',
          itemStyle: {
            normal: {
              color: '#1455B4',
              label: {
                show: true,      //开启显示
                position: 'top', //在上方显示
                textStyle: {     //数值样式
                  color: 'black',
                  fontSize: 12
                }
              }
            }
          }
        }
      ]
    };
    myChart1.setOption(option1);
  }
  useEffect(()=>{
    initChartRender(chartList)
  }, [chartList])

  return <div style={{'background': "#fff", 'paddingTop': '10px'}}>
    <div ref={dom} style={{ height: 300, width: 500, margin: '0 auto' }}></div>
  </div>

}

export default vehicleType;
